import Router from "./routes";
import ThemeProvider from "./theme";
import ThemeLocalization from "./locales";
import { StyledChart } from "./components/chart";
import SnackbarProvider from "./components/snackbar";
import { ThemeSettings } from "./components/settings";
import { MotionLazyContainer } from "./components/animate";
import { Toaster, toast } from "react-hot-toast";
import { useSelector } from "react-redux";
import { toastoptions } from "./utils/toastoptions";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { emptyErrorMessage } from "./redux/slices/error.slice";
import { Helmet } from "react-helmet-async";
import { addUserSpendTimeAsync } from "redux/async.api";
import { LanguageConvertAsync } from "redux/language/language.async";
import "./styles.css";
//inspect remove code
function disableRightClick(e) {
  e.preventDefault();
}

function handleKeyDown(e) {
  if (
    (e.ctrlKey && e.shiftKey && e.code === "KeyI") ||
    (e.ctrlKey && e.shiftKey && e.code === "KeyJ")
  ) {
    e.preventDefault();
  }
}
// inspect remove  code
export default function App() {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.error);
  const languageData = useSelector((state) => state.dataLangDetail);
  const { languageAsync } = useSelector((state) => state.languageData);
  const { getOnlySiteSettingLoader, getOnlySiteSettingData = [] } = useSelector(
    (state) => state.getOnlySiteSetting
  );
  const { siteLogo, siteAuthorName, favicon, siteTitle } =
    getOnlySiteSettingData;

  // useEffect(() => {
  //   document.addEventListener("contextmenu", disableRightClick);
  //   return () => {
  //     document.removeEventListener("contextmenu", disableRightClick);
  //   };
  // }, []);

  const getLanguage = localStorage.getItem("lang");

  useEffect(() => {
    if (getLanguage) {
      const payload = {
        language: getLanguage,
      };
      dispatch(LanguageConvertAsync(payload));
    }
  }, [getLanguage, languageData]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // user time spend
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = () => {
    // Call your API here
    dispatch(
      addUserSpendTimeAsync({
        status: "close",
      })
    );
  };

  useEffect(() => {
    // GLOBAL API ERROR
    if (error) {
      toast.error(error, toastoptions);
      dispatch(emptyErrorMessage());
    }
  }, [error]);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <ThemeSettings>
          <ThemeLocalization>
            <SnackbarProvider>
              <StyledChart />
              <Helmet>
                <title>{siteTitle}</title>
                <link rel="icon" type="image/png" href={favicon} />
              </Helmet>
              <Router />
              <Toaster reverseOrder={false} />
            </SnackbarProvider>
          </ThemeLocalization>
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
