import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { createEventRequestAsync, getAllEventByStudentIdAsync, getCalendarMonthAsync } from "../async.api";

const initialState = {
   getAllEventLoader:false, 
   getAllEvent:[],
   createEventRequest:[],
   createEventRequestLoader:false,
   getCalenderDataLoader:false,
   getCalenderData:[],
};

export const liveSlice = createSlice({
    name: "live",
    initialState,
    extraReducers: (builder) => {
        builder.addMatcher(isAnyOf(getAllEventByStudentIdAsync.pending), (state) => {
            state.getAllEventLoader = true;
        });
        builder.addMatcher(isAnyOf(getAllEventByStudentIdAsync.fulfilled), (state, action) => {
            state.getAllEvent = action?.payload?.data;
            state.getAllEventLoader = false;
        });
        builder.addMatcher(isAnyOf(getAllEventByStudentIdAsync.rejected), (state, action) => {
            state.getAllEventLoader = false;
        });
        // create event / book an event
        builder.addMatcher(isAnyOf(createEventRequestAsync.pending), (state) => {
            state.createEventRequestLoader = true;
        });
        builder.addMatcher(isAnyOf(createEventRequestAsync.fulfilled), (state, action) => {
            state.createEventRequest = action.payload;
            state.createEventRequestLoader = false;
        });
        builder.addMatcher(isAnyOf(createEventRequestAsync.rejected), (state, action) => {
            state.createEventRequestLoader = false;
        });
        // get calender data for demo and mentorship
        builder.addMatcher(isAnyOf(getCalendarMonthAsync.pending), (state) => {
            state.getCalenderDataLoader = true;
        });
        builder.addMatcher(isAnyOf(getCalendarMonthAsync.fulfilled), (state, action) => {
            state.getCalenderData = action.payload;
            state.getCalenderDataLoader = false;
        });
        builder.addMatcher(isAnyOf(getCalendarMonthAsync.rejected), (state, action) => {
            state.getCalenderDataLoader = false;
        });
    },
});

export default liveSlice.reducer;
