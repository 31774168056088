
import { createSlice } from '@reduxjs/toolkit';

const dataLangSlice = createSlice({
  name: 'langdata',
  initialState: [],
  reducers: {
    setLangData: (state, action) => {
      return action.payload;
    },
  },
});

export const { setLangData } = dataLangSlice.actions;
export default dataLangSlice.reducer;