import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { LanguageConvertAsync } from "./language.async";

const initialState = {
  languageLoader: false,
  languageAsync: {},
};

export const languageSlice = createSlice({
  name: "language",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(LanguageConvertAsync.pending), (state) => {
      state.languageLoader = true;
    });
    builder.addMatcher(
      isAnyOf(LanguageConvertAsync.fulfilled),
      (state, action) => {
        state.languageLoader = false;
        state.languageAsync = action.payload.data;
      }
    );

    builder.addMatcher(
      isAnyOf(LanguageConvertAsync.rejected),
      (state, action) => {
        state.languageLoader = false;
      }
    );
  },
});

export default languageSlice.reducer;
