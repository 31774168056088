import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import { useLocation, useNavigate } from "react-router";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { createTestByVideoTopicIdAsync } from "redux/async.api";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import { PATH_PAGE } from "../../../routes/paths";
import { stubTrue } from "lodash";

export const ResourceOpen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    getTestDetail = {},
    getExamSummary = {},
    createVideoTestDetail = {},
  } = useSelector((state) => state.test);
  const { url, id, resourceType, batchId } = location.state;
  const { learningAsync } = useSelector((state) => state?.syllabusAsy);
  const dispatch = useDispatch();
  //const  id2  = useParams();

  const dataById = learningAsync.filter(
    (item) => item.id === id && item.resourceType == "pdf"
  );

  console.log(
    "dataById[0]?.isTestAttempted",
    dataById[0]?.isTestAttempted,
    dataById[0]?.isBatchExpired
  );
  
  useEffect(() => {
    if (
      dataById[0]?.isTestAttempted === false &&
      dataById[0]?.isBatchExpired === false
    ) {
      if (
        dataById[0]?.subjectId !== undefined &&
        dataById[0]?.chapterId !== undefined
      ) {
        dispatch(
          createTestByVideoTopicIdAsync({
            videoId: dataById[0]?.videoId || 0,
            topicId: id,
            subjectId: dataById[0]?.subjectId || 0,
            chapterId: dataById[0]?.chapterId || 0,
            testTime: "00:15:00",
            numberOfQuestions: 30,
            batchId: batchId,
          })
        );
      }
    }
  }, []);

  const handleNavBackPagePdf = () => {
    if (
      dataById[0]?.isTestAttempted === false &&
      dataById[0]?.isTestMandatory === true &&
      dataById[0]?.isBatchExpired === false
    ) {
      navigate(
        `${PATH_PAGE.startQuiz}/${createVideoTestDetail?.id}?type=my_test`,
        {
          state: {
            batchId: batchId,
            isFinalTest: false,
          },
        }
      );
    } else {
      // navigate(-1);
      navigate("/app/syllabus");
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "left", mb: 1 }}>
        <KeyboardBackspaceIcon
          sx={{ color: "primary.main", cursor: "pointer", fontSize: "35px" }}
          onClick={handleNavBackPagePdf}
        />
      </Box>
      {resourceType === "image" ? (
        <Card
          sx={{ height: "75vh", width: "100%", mt: 4, borderRadius: "20px" }}
        >
          <img
            src={url}
            sx={{ width: "100%", height: "100%", objectFit: "contain" }}
          ></img>
        </Card>
      ) : (
        <embed
          oncontextmenu="return false"
          src={url + "#toolbar=0"}
          type="application/pdf"
          height={1000}
          width={1000}
        />
      )}
    </>
  );
};
