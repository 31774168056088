import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import useOffSetTop from "hooks/useOffSetTop";
import useResponsive from "hooks/useResponsive";
import { HEADER, NAV } from "config";
import Logo from "components/logo";
import Iconify from "components/iconify";
import { useSettingsContext } from "components/settings";
import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import {setLangData} from "redux/language/languageData.slice";
import { useDispatch } from "react-redux";
import { LanguageConvertAsync } from "redux/language/language.async";
export default function Header({ onOpenNav }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { studentById } = useSelector((state) => state?.student);
  const { name } = studentById;
  const { themeLayout } = useSettingsContext();
  const { languageAsync } = useSelector((state) => state.languageData);
  const languageData = useSelector((state) => state.dataLangDetail);

  const getLanguage = localStorage.getItem("lang");

  useEffect(() => {
    if (getLanguage) {
      const payload = {
        language: getLanguage,
      };
      dispatch(LanguageConvertAsync(payload));

    }
  }, []);

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        // border= "2px solid lightgray"
        // padding= "15px 25px"
        // borderRadius="10px"
        // box-shadow= "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
        spacing={{ xs: 0.5, sm: 1.5, backgroundColor:"#fff" }}
      >
        <Box>
          <Typography
            sx={{
              fontFamily: "sans-serif",
              color: "black",
              fontSize: "20px",
              fontWeight: "600",
              // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
            }}
          >
            {languageAsync.hello
              ? `${languageAsync.hello} ${name}`
              : `Hello, ${name}`}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* <FormControl size="small" sx={{ width: 150 }}>
            <InputLabel>Language</InputLabel>
            <Select
              label="Language"
              value={localStorage.getItem("lang")}
              onChange={handleChange}
              sx={{ border: "1px solid #fff", backgroundColor: "white" }}
            >
              <MenuItem value="hindi">Hindi</MenuItem>
              <MenuItem value="english">English</MenuItem>
            </Select>
          </FormControl> */}

          <NotificationsPopover sx={{ mr: 2 }} />
          <AccountPopover />
        </Box>
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        bgcolor: "primary.main",
        boxShadow: "none",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD + 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
