import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getStaffListAsync,
  staffMarkAttandenceAsync,
  staffAttendanceListAsync,
} from "./attendance.async";

const initialState = {
  staffLoader: false,
  staffByBatch: [],
  markAttendanceLoader: false,
  markAttendance: [],
  attendanceLoader: false,
  getAttendance: [],
};

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(getStaffListAsync.pending), (state, action) => {
      state.staffLoader = true;
      state.staffByBatch = [];
    });

    builder.addMatcher(
      isAnyOf(getStaffListAsync.fulfilled),
      (state, action) => {
        state.staffLoader = false;
        state.staffByBatch = action.payload.data;
      }
    );

    builder.addMatcher(isAnyOf(getStaffListAsync.rejected), (state, action) => {
      state.staffLoader = false;
      state.staffByBatch = [];
    });

    builder.addMatcher(
      isAnyOf(staffMarkAttandenceAsync.pending),
      (state, action) => {
        state.markAttendanceLoader = true;
      }
    );

    builder.addMatcher(
      isAnyOf(staffMarkAttandenceAsync.fulfilled),
      (state, action) => {
        state.markAttendanceLoader = false;
        state.markAttendance = action.payload.data;
      }
    );

    builder.addMatcher(
      isAnyOf(staffMarkAttandenceAsync.rejected),
      (state, action) => {
        state.markAttendanceLoader = false;
      }
    );

    builder.addMatcher(isAnyOf(staffAttendanceListAsync.pending), (state) => {
      state.attendanceLoader = true;
    });

    builder.addMatcher(
      isAnyOf(staffAttendanceListAsync.fulfilled),
      (state, action) => {
        state.attendanceLoader = false;
        state.getAttendance = action.payload;
      }
    );

    builder.addMatcher(
      isAnyOf(staffAttendanceListAsync.rejected),
      (state, action) => {
        state.attendanceLoader = false;
      }
    );
  },
});

export default attendanceSlice.reducer;
