import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const staffBatchesAsync = createAsyncThunk(
  "web/staffBatches",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/staffBatches?type=${payload.type}`,
      payload,
      toolkit
    );
  }
);

export const getContentsByTopicIdAsync = createAsyncThunk(
  "web/getContentsByTopicId",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getSyllabusContentByTopicId?topicId=${payload.topicId}&batchId=${payload.batchId}`,
      payload,
      toolkit
    );
  }
);

export const getSyllabusContentByIdAsync = createAsyncThunk(
  "web/getSyllabusContentById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getSyllabusContentById/${payload}`,
      [],
      toolkit
    );
  }
);

export const getLearningContentByIdAsync = createAsyncThunk(
  "web/getLearningContentById",
  async (payload, toolkit) => {
    return await AxiosClient(
      "POST",
      `/getLearningContentById`,
      payload,
      toolkit
    );
  }
);
//resumeLearning
export const resumeLearningAsync = createAsyncThunk(
  "user/resumeLearning",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/resumeLearning?subjectId=${payload.subjectId}&chapterId=${payload.chapterId}&batchId=${payload.batchId}`,
      payload,
      toolkit
    );
  }
);
// addRecentActivity
export const addRecentActivityAsync = createAsyncThunk(
  "user/addRecentActivity",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/addRecentActivity`, payload, toolkit);
  }
);
