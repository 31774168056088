import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getPollByUserIdAsync, submitPollByUserIdAsync } from "../async.api";

const initialState = {
  getPollLoader: false,
  getPollData: [],
  submitPollLoader: false,
  submitPollData: []
};

export const pollSlice = createSlice({
  name: "polls",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getPollByUserIdAsync.pending),
      (state) => {
        state.getPollLoader = true;
      }
    );
    builder.addMatcher(isAnyOf(getPollByUserIdAsync.fulfilled), (state, action) => {
      state.getPollLoader = false;
      state.getPollData = action.payload;
    });
    builder.addMatcher(isAnyOf(getPollByUserIdAsync.rejected), (state, action) => {
      state.getPollLoader = false;
    });

    builder.addMatcher(
        isAnyOf(submitPollByUserIdAsync.pending),
        (state) => {
          state.submitPollLoader = true;
        }
      );
      builder.addMatcher(isAnyOf(submitPollByUserIdAsync.fulfilled), (state, action) => {
        state.submitPollLoader = false;
        state.submitPollData = action.payload;
      });
      builder.addMatcher(isAnyOf(submitPollByUserIdAsync.rejected), (state, action) => {
        state.submitPollLoader = false;
      });
  },
});

export default pollSlice.reducer;