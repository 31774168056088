import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const getStaffListAsync = createAsyncThunk(
  "web/getStaffList",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/getStaffList?batchId=${payload.batchId}`,
      payload,
      toolkit
    );
  }
);

export const staffMarkAttandenceAsync = createAsyncThunk(
  "web/staffMarkAttandence",
  async (payload, toolkit) => {
    return await AxiosClient("POST", `/staffMarkAttandence`, payload, toolkit);
  }
);

export const staffAttendanceListAsync = createAsyncThunk(
  "admin/staffAttendanceList",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/staffAttendanceList?page=${payload.page}&limit=${
        payload.limit
      }&batchId=${payload.batchId || ""}`,
      [],
      toolkit
    );
  }
);
