import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosClient } from "../AxiosClient";

export const LanguageConvertAsync = createAsyncThunk(
  "web/LanguageConvert",
  async (payload, toolkit) => {
    return await AxiosClient(
      "GET",
      `/LanguageConvert?language=${payload.language}`,
      payload,
      toolkit
    );
  }
);
