import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getContentsByTopicIdAsync,
  getLearningContentByIdAsync,
  getSyllabusContentByIdAsync,
  resumeLearningAsync,
  addRecentActivityAsync,
  staffBatchesAsync,
} from "./syllabus.async";

const initialState = {
  learningLoader: false,
  learningAsync: [],
  contentByTopic: [],
  contentLoading: false,
  contentObj: {},
  resumeLearningLoader: false,
  resumeLearning: [],
  recentActivityLoader: false,
  recentActivityData: [],
  trainingBatchLoader: false,
  trainingBatchData: [],
};

export const syllabusSlice = createSlice({
  name: "syllabus",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(isAnyOf(staffBatchesAsync.pending), (state, action) => {
      state.trainingBatchLoader = true;
      state.trainingBatchData = [];
    });
    builder.addMatcher(
      isAnyOf(staffBatchesAsync.fulfilled),
      (state, action) => {
        state.trainingBatchLoader = false;
        state.trainingBatchData = action.payload.data;
      }
    );
    builder.addMatcher(isAnyOf(staffBatchesAsync.rejected), (state, action) => {
      state.trainingBatchLoader = false;
      state.trainingBatchData = [];
    });

    builder.addMatcher(
      isAnyOf(getContentsByTopicIdAsync.pending),
      (state, action) => {
        state.learningLoader = true;
        state.contentByTopic = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getContentsByTopicIdAsync.fulfilled),
      (state, action) => {
        state.learningLoader = false;
        state.contentByTopic = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getContentsByTopicIdAsync.rejected),
      (state, action) => {
        state.learningLoader = false;
        state.contentByTopic = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getLearningContentByIdAsync.pending),
      (state, action) => {
        state.learningLoader = true;
        state.learningAsync = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getLearningContentByIdAsync.fulfilled),
      (state, action) => {
        state.learningLoader = false;
        state.learningAsync = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getLearningContentByIdAsync.rejected),
      (state, action) => {
        state.learningLoader = false;
        state.learningAsync = [];
      }
    );
    builder.addMatcher(
      isAnyOf(getSyllabusContentByIdAsync.pending),
      (state, action) => {
        state.contentLoading = true;
        state.contentObj = {};
      }
    );
    builder.addMatcher(
      isAnyOf(getSyllabusContentByIdAsync.fulfilled),
      (state, action) => {
        state.contentLoading = false;
        state.contentObj = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(getSyllabusContentByIdAsync.rejected),
      (state, action) => {
        state.contentLoading = false;
        state.contentObj = {};
      }
    );
    builder.addMatcher(
      isAnyOf(resumeLearningAsync.pending),
      (state, action) => {
        state.resumeLearningLoader = true;
        state.resumeLearning = [];
      }
    );
    builder.addMatcher(
      isAnyOf(resumeLearningAsync.fulfilled),
      (state, action) => {
        state.resumeLearningLoader = false;
        state.resumeLearning = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(resumeLearningAsync.rejected),
      (state, action) => {
        state.resumeLearningLoader = false;
        state.resumeLearning = [];
      }
    );

    builder.addMatcher(
      isAnyOf(addRecentActivityAsync.pending),
      (state, action) => {
        state.recentActivityLoader = true;
        state.recentActivityData = [];
      }
    );
    builder.addMatcher(
      isAnyOf(addRecentActivityAsync.fulfilled),
      (state, action) => {
        state.recentActivityLoader = false;
        state.recentActivityData = action.payload.data;
      }
    );
    builder.addMatcher(
      isAnyOf(addRecentActivityAsync.rejected),
      (state, action) => {
        state.recentActivityLoader = false;
        state.recentActivityData = [];
      }
    );
  },
});

export default syllabusSlice.reducer;
